import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import { connect, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import TextArea from "../../../components/TextArea";
import { apiUpload } from "../../../api/apiUpload";
import ProgessUpload from "../../../components/FormPage/ProgressUpload";

const EditCampaignPage = ({ id, ...props }) => {
    TabTitle("Profile");
    const navigate = useNavigate();

    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });
    const [selectedImage, setSelectedImage] = useState(null); // State for the selected image
    const [imgFile, setImgFile] = useState(null);

    const [statusUpload, setStatusUpload] = useState("");
    const [progressUpload, setProgressUpload] = useState(0);

    const [isEditing, setIsEditing] = useState(false); // New state to track if editing
    const [campaignId, setCampaignId] = useState(null); // New state for campaign ID

    const handleBack = () => {
        navigate("/");
    };

    // New function to fetch campaign data
    const fetchCampaignData = async (id) => {
        try {
            const { data } = await apiUpload.get(`/v1/campaign/${id}`);
            setSelectedImage(data.data.image.resourceUrl); // Assuming the API returns an image URL
            // setImgFile(data.imageFile); // Assuming the API returns the file
            document.getElementById('title').value = data.data.title;
            document.getElementById('desc').value = data.data.description;
            document.getElementById('target').value = parseFloat(data.data.goal_amount).toFixed(0); // Parse and remove two digits behind the comma
            document.getElementById('dateStart').value = data.data.start_date;
            document.getElementById('dateEnd').value = data.data.end_date;
            setIsEditing(true);
            setCampaignId(id); // Set the campaign ID for updates
        } catch (error) {
            console.error("Error fetching campaign data:", error);
        }
    };

    useEffect(() => {// Assuming you're using react-router
        if (id) {
            fetchCampaignData(id); // Fetch data if an ID is present
        }
    }, [id]);

    const postCampaign = async () => {
        const formData = new FormData();
        formData.append("image", imgFile);

        // Add values from text fields
        formData.append("title", document.getElementById('title').value);
        formData.append("description", document.getElementById('desc').value);
        formData.append("goal_amount", document.getElementById('target').value);
        formData.append("start_date", formatDate(document.getElementById('dateStart').value));
        formData.append("end_date", formatDate(document.getElementById('dateEnd').value));

        console.log(formData);
        try {
            setModalOpen({ status: false, type: "" });
            setStatusUpload("uploading");
            const endpoint = isEditing ? `/v1/campaign/${campaignId}` : `/v1/campaign`; // Use the correct endpoint
            const method = isEditing ? 'put' : 'post'; // Use PUT for updates

            await apiUpload[method](endpoint, formData, {
                onUploadProgress: (progressEvent) => {
                    setProgressUpload(
                        Math.round((progressEvent.loaded / progressEvent.total) * 100)
                    );
                },
            }).then(({ response }) => {
                setStatusUpload("success");
                navigate("/dashboard/campaign");
            });
        } catch ({ response }) {
            setStatusUpload("failed");
            const errorMsg = response.data;
            if (errorMsg.error.errors[0].message) {
                alert(errorMsg.error.errors[0].message);
            }
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <>
            {statusUpload !== "" && (
                <ProgessUpload
                    status={statusUpload}
                    setStatus={setStatusUpload}
                    progress={progressUpload}
                />
            )}
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />

            <div className="row-span-2 col-span-9 bg-white">
                <div className="border-b-2 p-4 border-bright flex mb-4">
                    <p className="text-2xl text-primary font-bold">Ubah Campaign</p>
                </div>
                <div className="flow-root p-4">
                    <div className='flex flex-col md:flex-row gap-8'>
                        <div className='flex flex-col mx-auto md:mx-0 w-full gap-6 md:w-64'>
                            <div className='w-64 h-48 overflow-hidden mx-auto md:mx-0'>
                                <div className='w-full h-full flex items-center justify-center' style={{ backgroundColor: selectedImage ? 'transparent' : '#f0f0f0' }}>
                                    {selectedImage ? ( // Conditional rendering for image preview
                                        <img src={selectedImage} alt="Selected" className="w-full h-full object-cover" />
                                    ) : (
                                        <div className="w-full h-full flex items-center justify-center">
                                            <span className="text-gray-500">Pilih Gambar</span> {/* Placeholder text */}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='w-full flex justify-center'>
                                <Button
                                    typeNavigation='button'
                                    btnStyle='secondary'
                                    btnType="secondary"
                                    customStyle='px-4 py-0.5 w-fit rounded-full'
                                    onClick={() => document.getElementById('imageUpload').click()} // Trigger file input
                                >
                                    <div className='pSmallBold'>Pilih Gambar</div>
                                </Button>
                                <input
                                    id='imageUpload'
                                    type='file'
                                    accept='image/*' // Accept only image files
                                    style={{ display: 'none' }} // Hide the input
                                    onChange={(e) => {
                                        e.preventDefault(); // Prevent default behavior
                                        const file = e.target.files[0];
                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                setSelectedImage(reader.result); // Update state with the selected image URL
                                            };
                                            reader.readAsDataURL(file); // Read the file as a data URL

                                            setImgFile(file);
                                        }
                                    }}
                                    onClick={(e) => e.stopPropagation()} // Prevent triggering postCampaign on click
                                />
                            </div>
                        </div>
                        <form
                            id='form-update-profile'
                            className='flex flex-col gap-6 lg:w-full md:min-w-max'>
                            <div className="flex flex-col gap-2">
                                <TextField
                                    inputId='title'
                                    inputName='title'
                                    label='Judul'
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                    placeholder='Masukkan Judul Campaign'
                                />
                            </div>

                            <div className="flex flex-col gap-2">
                                <TextArea
                                    isRequired={false}
                                    label='Deskripsi'
                                    inputId='desc'
                                    inputName='desc'
                                    placeholder="Masukkan Deskripsi"
                                />
                            </div>

                            <div className="flex flex-col gap-2">
                                <TextField
                                    inputId='target'
                                    inputName='target'
                                    type='number'
                                    label='Target Donasi'
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                    placeholder='Masukkan Target Donasi'
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <TextField
                                    inputId='dateStart'
                                    inputName='dateStart'
                                    type='date'
                                    label='Tanggal Mulai'
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                />
                            </div>

                            <div className="flex flex-col gap-2">
                                <TextField
                                    inputId='dateEnd'
                                    inputName='dateEnd'
                                    type='date'
                                    label='Tanggal Selesai'
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                />
                            </div>
                        </form>
                    </div>
                    <div className='flex flex-row justify-end mt-4'>
                        <Button
                            typeNavigation='button'
                            btnType='primary'
                            btnStyle='primary'
                            customStyle='px-4 py-0.5 w-fit rounded-full'
                            form='form-update-profile'
                            onClick={postCampaign} // Pass the function reference
                        >
                            <div>Simpan</div>
                        </Button>
                    </div>
                </div>
            </div>

        </>
    );
}

export default connect(null)(EditCampaignPage)