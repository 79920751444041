import news from '../../../assets/images/banner/news.png';
import { useState } from 'react'; // Import useState for managing state
import { formatRupiah } from '../../../utils/RupiahFormat';

function Penyaluran({ campaign, ...props }) { // Added 'images' prop
    const [selectedImage, setSelectedImage] = useState(null); // State to manage the selected image

    return (
        <section id="headline">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="grid grid-rows-1 gap-12">
                    <div className="grid grid-cols-1 gap-8">
                        <div>
                            <div className="text-default text-sm">Tanggal Disalurkan {campaign.distribute_date}</div>
                            <p className="text-limit text-default text-sm mt-2 ">Total Disalurkan : {formatRupiah(campaign.distribute_amount)}</p>
                            <p className="text-default text-sm">{campaign.notes}</p>
                            <div className="flex space-x-4 mt-2"> {/* Added flex container for images */}
                                {campaign.evidence.items.map((image, index) => ( // Mapping over images array
                                    <img 
                                        key={index} 
                                        className="max-h-[200px] w-auto object-cover cursor-pointer" 
                                        src={image.resourceUrl} 
                                        alt={`Image ${index + 1}`} 
                                        onClick={() => setSelectedImage(image.resourceUrl)} // Set selected image on click
                                    />
                                ))}
                            </div>
                            {selectedImage && ( // Conditionally render the zoomed image
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={() => setSelectedImage(null)}> // Added z-index
                                    <img className="max-h-[80%] max-w-[80%] object-contain" src={selectedImage} alt="Zoomed" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default Penyaluran;