import React from "react";
import Button from "../../../components/Button";
import {connect, useSelector} from 'react-redux';
import Icon from "@mdi/react";
import {NavLink} from "react-router-dom";
import {mdiHome, mdiGift, mdiLogout} from "@mdi/js";

const NavProfile = (props) => {
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/login';
    }

    return (
        <>
            <div className="row-span-6 col-span-3 p-4 bg-white rounded-xl drop-shadow-bottom">
                <div className="divide-y divide-bright dark:divide-gray-700">
                    <NavLink to="/dashboard" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiHome} size={0.8} className="text-primary" />
                            <div>Beranda</div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/campaign" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiGift} size={0.8} className="text-primary" />
                            <div>Program Donasi</div>
                        </div>
                    </NavLink>
                    {/* <NavLink to="/dashboard/orders" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiCartOutline} size={0.8} className="text-primary" />
                            <div>Reservasi</div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/vouchers" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiTicketPercentOutline} size={0.8} className="text-primary" />
                            <div>Voucher Saya</div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/payments" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiCreditCard} size={0.8} className="text-primary" />
                            <div>Default Payment</div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/password" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiLockOutline} size={0.8} className="text-primary" />
                            <div>Ganti Kata Sandi</div>
                        </div>
                    </NavLink> */}
                    <div onClick={handleLogout} className="cursor-pointer flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiLogout} size={0.8} className="text-primary" />
                            <div>Logout</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect(null)(NavProfile)
