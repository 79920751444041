import React from "react";
import SocialImpact from "../../../assets/vectors/icons/social-impact.png";
import Sustainibility from "../../../assets/vectors/icons/sustainibility.png";
import MediaIntegrated from "../../../assets/vectors/icons/media-integrated.png";
import ConnectingPeople from "../../../assets/vectors/icons/connecting-people.png";
import Monev from "../../../assets/vectors/icons/monev.png";
import {ReactComponent as General} from "../../../assets/vectors/icons/general-cleaning.svg";
import {ReactComponent as Deep} from "../../../assets/vectors/icons/deep-cleaning.svg";
import {ReactComponent as Toilet} from "../../../assets/vectors/icons/toilet-cleaning.svg";
import {ReactComponent as Furniture} from "../../../assets/vectors/icons/furniture-cleaning.svg";
import {ReactComponent as Hydro} from "../../../assets/vectors/icons/hydro-vaccum.svg";
import {ReactComponent as Poles} from "../../../assets/vectors/icons/poles-lantai.svg";
import {ReactComponent as Penyemprotan} from "../../../assets/vectors/icons/penyemprotan-disinfektan.svg";
import {ReactComponent as OneHome} from "../../../assets/vectors/icons/one-home-one-hope.svg";
import {ReactComponent as OneCorp} from "../../../assets/vectors/icons/one-corp-many-hope.svg";
import bgtop from "../../../assets/images/textures/bg-top.png";
import Button from "../../Button";
import {NavLink} from "react-router-dom";
import { SvgIcon } from "@mui/material";

function SectionKenapaKami() {
  let services = [
    {
      title: "Social Impact",
      desc: "Memetakan program berbasis bottom-up planning sehingga menghasilakn ide program yang memiliki dampak secara sosial dan terupdate",
      icon: SocialImpact,
      link: "/services/general-cleaning",
    },
    {
      title: "Sustainibility Program",
      desc: "Program yang berkelanjutan menjadi kunci keberlangsungan kebermanfaatn untuk masyarakat",
      icon: Sustainibility,
      link: "/services/deep-cleaning",
    },
    {
      title: "Media Integrated",
      desc: "Terinegrasi dengan ekosistem media membuat program ini punya daya tarik kolaborasi dengan pihak-pihak lain",
      icon: MediaIntegrated,
      link: "/services/toilet-cleaning",
    },
    {
      title: "Connecting People",
      desc: "Membangun jaringan komunitas baik lokal maupun nasional untuk bersinergi dan memberikan dukungan bagi kesuksesan program",
      icon: ConnectingPeople,
      link: "/services/furniture-cleaning",
    },
    {
      title: "Monev",
      desc: "Melakukan monitoring dan evaluasi secara berkelanjutan untuk menjamin program berjalan optimal.",
      icon: Monev,
      link: "/services/hydro-vaccum",
    },
    {
      title: "Customize Program",
      desc: "Program bisa menyesuaikan keinginan dan kebutuhan dari pelanggan",
      icon: Monev,
      link: "/services/poles-lantai",
    }
  ]
  return (
    <section className="py-16 bg-primaryLight" id="services">
      <article className="relative max-w-container mx-auto px-6 md:px-4">
        <img className="-z-10 absolute top-0 left-0" src={bgtop} alt="" />
        <h2 className="text-center font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat mb-8">
          Kenapa harus {" "}
          <span className="text-primary">Langkah</span>Baik.id ?
        </h2>
        <div className="grid grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div className="p-8 col-span-3 lg:col-span-1 bg-white rounded-[32px] gap-4 flex flex-col">
              <div className="flex items-center">
                <div className="rounded-full p-2">
                  <div className="w-14 h-14 text-4xl text-white flex items-center justify-center">
                    <img src={service.icon} alt="" />
                  </div>
                </div>
                <div className="ml-4 text-2xl text-primaryDark font-bold">
                  {service.title}
                </div>
              </div>
              <div className="text-base text-default mt-4 mb-4">
                {service.desc}
              </div>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
}

export default SectionKenapaKami;
