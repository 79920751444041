import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../../components/Button";
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/logo/Logo.png";
import Footer from "../../../components/LandingPage/Footer";
import NavProfile from "../Component/NavProfile";
import api from "../../../api";
import { formatRupiah } from "../../../utils/RupiahFormat";

const CampaignPage = (props) => {
    TabTitle("Dashboard");
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

    const [campaign, setCampaign] = useState([]);

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    useEffect(() => {
        getCampaigns();
    }, []);

    const getCampaigns = async () => {
        try {
            const response = await api.get("/v1/campaign");
            console.log(response.data.data);

            setCampaign(response.data.data);
        } catch ({ response }) {
            console.log(response);
        }
    };

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />
            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-1 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                        </div>
                        <div className="col-span-11 grid grid-cols-11">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Dashboard
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="bg-neutralLight font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-2 lg:max-w-full relative gap-4 lg:gap-y-6 lg:gap-x-4 py-2">
                <NavProfile />

                <div className="row-span-2 col-span-9 bg-white rounded-xl shadow">
                    <div className="flex justify-end mb-4 p-4"> {/* Added this div for alignment */}
                        <NavLink to={"/dashboard/campaign/add"}>
                            <Button customStyle=" w-full">
                                <div className="w-full text-center not-italic font-bold text-sm lg:text-base leading-[140%] tracking-[0.005em] text-white py-1 px-3 md:px-5">
                                    Tambah
                                </div>
                            </Button>
                        </NavLink>
                    </div>
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 text-center">No</th>
                                <th className="px-4 py-2 text-center">Thumbnail</th>
                                <th className="px-4 py-2 text-center">Judul</th>
                                <th className="px-4 py-2 text-center">Periode</th>
                                <th className="px-4 py-2 text-center">Target Donasi</th>
                                <th className="px-4 py-2 text-center">Terkumpul</th>
                                <th className="px-4 py-2 text-center">Status</th>
                                <th className="px-4 py-2 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {campaign.map((item, index) => (
                                <tr key={item.id}>
                                    <td className="px-4 py-2 text-center">{index + 1}</td>
                                    <td className="px-4 py-2 text-center">
                                        <img src={item.image.resourceUrl} alt={item.title} className="w-16 h-16 object-cover" /> {/* Adjusted size */}
                                    </td>
                                    <td className="px-4 py-2 text-center">{item.title}</td>
                                    <td className="px-4 py-2 text-center">{item.start_date} - {item.end_date}</td>
                                    <td className="px-4 py-2 text-center">{formatRupiah(item.goal_amount)}</td> 
                                    <td className="px-4 py-2 text-center">{formatRupiah(item.raised_amount)}</td>
                                    <td className="px-4 py-2 text-center">{item.status}</td>
                                    <td className="px-4 py-2 text-center">
                                        <button
                                            className="text-blue-500"
                                            onClick={() => navigate(`/dashboard/campaign/detail`, {
                                                state: { id: item.id }
                                            })}
                                        >
                                            Detail
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </section>
            <footer id="footer">
                <Footer />
            </footer>
        </>
    );
}

export default connect(null)(CampaignPage)
