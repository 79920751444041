import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState } from "react";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/logo/Logo.png";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import Footer from "../../../components/LandingPage/Footer";
import { NavLink } from "react-router-dom";
import { mdiLockOutline, mdiTicketPercentOutline, mdiCartOutline, mdiHomeMapMarker, mdiLogout } from "@mdi/js";
import NavProfile from "../Component/NavProfile";

const AccountPage = (props) => {
    TabTitle("Profile");
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    const handleBack = () => {
        navigate("/");
    };

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />
            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-1 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                        </div>
                        <div className="col-span-11 grid grid-cols-11">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Dashboard
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>


            <section className="font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-2 lg:max-w-full relative gap-4 lg:gap-y-6 lg:gap-x-4 py-0">
                <NavProfile />

                <div className="row-span-2 col-span-9 bg-white rounded-3xl drop-shadow-bottom">
                <div className="border-b-2 p-4 border-bright flex mb-4">
                            <p className="text-2xl text-primary font-bold">Profil</p>
                        </div>
                    <div className="flow-root p-4">
                    
                        <div className='flex flex-col md:flex-row gap-8'>
                            <div className='flex flex-col mx-auto md:mx-0 w-full gap-6 md:w-32'>
                                <div className='w-32 h-32 rounded-full overflow-hidden mx-auto md:mx-0 '>
                                    <div className=' w-full h-full' >
                                        <img src="/img/initials/1_d.png" />
                                    </div>
                                </div>
                                <div className='w-full flex justify-center'>
                                    <Button
                                        typeNavigation='button'
                                        btnStyle='secondary'
                                        btnType="secondary"
                                        customStyle='px-4 py-0.5 w-fit rounded-full'>
                                        <div className='pSmallBold'>Ubah</div>
                                    </Button>
                                </div>
                            </div>
                            <form
                                id='form-update-profile'
                                className='flex-grow grid grid-cols-2 gap-3 w-full'>
                                <TextField
                                    inputId='name'
                                    inputName='name'
                                    label='Nama Lengkap'
                                    isRequired
                                    defaultValue={profile.user.name}
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3 '
                                    placeholder='Masukkan nama lengkap Anda'
                                />


                                <TextField
                                    inputId='email'
                                    inputName='email'
                                    type='email'
                                    label='Email'
                                    defaultValue={profile.user.email}
                                    isRequired
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3'
                                    placeholder='Masukkan email Anda'
                                />
                                <TextField
                                    inputId='phoneNumber'
                                    pattern='^-?[0-9]\d*\.?\d*$'
                                    inputName='phone'
                                    type='tel'
                                    defaultValue={profile.user.phone}
                                    label={
                                        <>
                                            Nomor HP <span className='text-neutralBright'>(opsional)</span>
                                        </>
                                    }
                                    customLabelStyle='col-span-4 sm:col-span-1'
                                    customContainerStyle='col-span-4 sm:col-span-3'
                                    placeholder='Masukkan nomor handphone Anda'
                                />
                            </form>
                        </div>
                        <div className='flex flex-row justify-end'>
                            <Button
                                typeNavigation='button'
                                btnType='primary'
                                btnStyle='primary'
                                customStyle='px-4 py-0.5 w-fit rounded-full'
                                form='form-update-profile'
                            >
                                <div>Simpan</div>
                            </Button>
                        </div>
                    </div>
                </div>

            </section>
            <footer id="footer">
                <Footer />
            </footer>
        </>
    );
}

export default connect(null)(AccountPage)
