import React from "react";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionAbout from "../../../components/LandingPage/HomeSection/SectionAbout";
import SectionBanner from "../../../components/LandingPage/HomeSection/SectionBanner";
import SectionProjectKami from "../../../components/LandingPage/HomeSection/SectionProjectKami";
import SectionKenapaKami from "../../../components/LandingPage/HomeSection/SectionKenapaKami";
import SectionLayanan from "../../../components/LandingPage/HomeSection/SectionLayanan";
import TabTitle from "../../../utils/TabTitle";

const HomePage = () => {
  TabTitle("Beranda - Langkah Baik");
  return (
    <>
      <SectionBanner />
      <SectionAbout />
      <SectionProjectKami />
      <SectionKenapaKami />
      <SectionLayanan />
      <FloatingWhatsapp />
    </>
  );
};

export default HomePage;
