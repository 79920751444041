import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../../components/Button";
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from "../../../api";

const CampaignNewsPage = ({ id, ...props }) => {
    TabTitle("Campaign - News");
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

    const [campaign, setCampaign] = useState([]);

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    useEffect(() => {
        getCampaigns();
    }, []);

    const getCampaigns = async () => {
        try {
            const response = await api.get("/v1/news/campaign");
            console.log(response.data.data);

            setCampaign(response.data.data);
        } catch ({ response }) {
            console.log(response);
        }
    };

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />

            <div className="row-span-2 col-span-9 bg-white">
                <div className="flex justify-end mb-4 p-4"> {/* Added this div for alignment */}
                    <Button customStyle="w-full" onClick={() => navigate("/dashboard/campaign/news/add", { state: { id: id } })}>
                        Tambah
                    </Button>
                </div>
                <table className="min-w-full">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 text-center">No</th>
                            <th className="px-4 py-2 text-center">Thumbnail</th>
                            <th className="px-4 py-2 text-center">Judul</th>
                            <th className="px-4 py-2 text-center">Tanggal</th>
                            <th className="px-4 py-2 text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaign.map((item, index) => (
                            <tr key={item.id}>
                                <td className="px-4 py-2 text-center">{index + 1}</td>
                                <td className="px-4 py-2 text-center">
                                    <img src={item.image.resourceUrl} alt={item.title} className="w-16 h-16 object-cover" /> {/* Adjusted size */}
                                </td>
                                <td className="px-4 py-2 text-center">{item.title}</td>
                                <td className="px-4 py-2 text-center">{item.created_at}</td>
                                <td className="px-4 py-2 text-center">
                                    <button
                                        className="text-blue-500"
                                        onClick={() => navigate(`/dashboard/campaign/news/edit`, {
                                            state: { id: item.id }
                                        })} // Added navigation action
                                    >
                                        Detail
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default connect(null)(CampaignNewsPage)
