import model from "../../../assets/images/misc/banner.png";
import {mdiArrowRight} from "@mdi/js";
import Icon from "@mdi/react";
import Button from "../../Button";
import {Link} from "react-router-dom";

function SectionBanner() {
  return (
    <section className="relative h-screen" id="beranda">
      <img src={model} alt="Banner Modal" className="absolute inset-0 w-full h-full object-cover" />
      <div className="absolute inset-0 bg-black bg-opacity-30"></div>
      <div className="relative z-10 flex flex-col justify-center h-full px-6 md:px-4 max-w-container mx-auto">
        <article className="flex flex-col gap-6 col-span-1 sm:col-span-6 md:col-span-4">
          <h1 className="montserrat text-white font-bold text-3xl lg:text-[48px] tracking-[0.005em] leading-[140%] lg:leading-[120%] w-1/2">
            Selamat Datang di Website LangkahBaik.id
          </h1>
          <Link
            to="/about"
            className="flex font-normal text-base leading-[140%] tracking-wider text-white"
          >
            <div>Tentang Kami</div>
            <div>
              <Icon path={mdiArrowRight} size={1} className="ml-[10px]" />
            </div>
          </Link>
        </article>
      </div>
    </section>
  );
}

export default SectionBanner;
