import news from '../../../assets/images/banner/news.png';
import { useNavigate } from 'react-router-dom'; // Update this import
import React, { useState, useEffect } from "react";
import api from '../../../api';

function NewsComponent() {
    const navigate = useNavigate(); // Initialize navigate
    const [campaign, setCampaign] = useState([]);

    const handleNavigate = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        navigate('/project-us/donate/news', { state: { title: 'Your Title' } }); // Update with your NewsPage route and parameters
    };

    useEffect(() => {
        getCampaigns();
    }, []);

    const getCampaigns = async () => {
        try {
            const response = await api.get("/v1/news/campaign");
            console.log(response.data.data);

            setCampaign(response.data.data);
        } catch ({ response }) {
            console.log(response);
        }
    };

    return (
        <section id="headline">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="grid grid-rows-5 gap-5">
                {campaign.map((item, index) => (
                    <div key={item.id} className="grid grid-cols-4 gap-6">
                        <div className="col-span-1 max-h-[178px] max-w-[258] object-cover">
                            <img src={item.image.resourceUrl} alt={item.title} className="w-full" />
                        </div>
                        <div className="col-span-3">
                            <div className="text-xs text-default">{item.created_at}</div>
                            <div className="text-lg text-dark montserrat font-bold mt-1 mb-1">{item.title}</div>
                            <p className="text-sm text-default">{item.content}</p>
                            <a href="#" className="text-sm text-primary" onClick={handleNavigate}>Selengkapnya</a>
                        </div>
                    </div>
                ))}

                </div>
            </article>
        </section>

    );
}

export default NewsComponent;