import React from 'react';
import SectionProjectKami from '../../../components/LandingPage/HomeSection/SectionProjectKami';
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import TabTitle from "../../../utils/TabTitle";

const ProjectUsPage = () => {
    TabTitle("Project Kami - Cleansheet");
    return (
        <>
        <SectionProjectKami />
        <FloatingWhatsapp />
        </>
    );
}

export default ProjectUsPage;