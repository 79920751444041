import logo from '../../../assets/images/logo/newLogo.jpg'

function SectionFoundation() {
    return (
        <section className="py-16 relative" id="foundation">
            <article className="grid grid-cols-3 gap-12 max-w-container mx-auto px-6 md:px-4">
                <div className="col-span-3 lg:col-span-2 flex flex-col gap-5">
                    <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                        <span className="underlined"><span className="text-primary">Langkah</span>Baik.id</span>
                    </h2>
                    <div className="mb-6">
                        <div>
                        Tingginya angka anak putus sekolah di Indonesia menjadi alasan utama kami untuk melakukan perubahan. Lahirlah gerakan “KAWAN DEWANTARA“ yang bertujuan untuk memberikan kesempatan kepada anak putus sekolah untuk melanjutkan pendidikan dan mempelajari berbagai bidang yang diinginkan agar memiliki masa depan yang lebih cerah. Kami juga akan memberikan kesempatan untuk bekerja bersama Cleansheet bersamaan dengan dilanjutkannya pendidikan. Setelah 5 tahun belajar dan berkembang bersama Cleansheet, kami akan meluluskan mereka dan membantu menyalurkan minat & bakat dengan merekomendasikan ke berbagai relasi dan perusahaan yang bekerja sama dengan Cleansheet.
                        </div>
                        <div className="mt-5">
                        Dalam 5 tahun kami akan berusaha untuk menyekolahkan 1000 anak putus sekolah, serta menyalurkan minat bakat mereka. Ayo bergerak bersama kami dan jadilah jembatan untuk masa depan mereka!
                        </div>
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-1 flex justify-center lg:justify-end">
                    <div className="">
                        <img className="max-w-[300px]" src={logo} alt="" />
                    </div>
                </div>
            </article>
        </section>

    );
}

export default SectionFoundation;